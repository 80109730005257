import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {Places, SelectOptions} from '../../models/common';
import {NgxSpinnerService} from 'ngx-spinner';
import * as constants from '../../constants';
import {ErrorHandlerService} from '../../services/error-handler.service';
import {PostEnquiryService} from '../../services/post-enquiry.service';
import Swal from 'sweetalert2';
import {languageConstants} from '../../constants/languageConstants';
import { GoogleLocationSelectorComponent } from '../google-location-selector/google-location-selector.component';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.css']
})
export class LocationSelectorComponent implements OnInit, OnChanges {
  addressForm: FormGroup;
  Location: Places;
  submittedForm = false;
  addressType: SelectOptions[] = constants.OTHER_ADDRESS_TYPE[localStorage.getItem('language') || 'en'];
  @Input() show: boolean;
  @Input() title: string;
  @Input() vehicle: number;
  @Output() closeModal = new EventEmitter();
  @Output() getAddress = new EventEmitter();
  loc: any;
  language = 'en';
  languageConstants = languageConstants;
  languageKeys = languageConstants[this.language];

  constructor(private fs: FormService,
              private spinner: NgxSpinnerService,
              private errorHandler: ErrorHandlerService,
              private postEnquiryService: PostEnquiryService,
  ) {
    const langu = localStorage.getItem('language');
    if(langu) {
      this.language = langu;
      this.languageKeys = this.languageConstants[langu]
    }
  }

  ngOnInit() {
    this.addressForm = this.makeAddressForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehicle) {
      this.vehicle = changes.vehicle.currentValue;
    }
  }

  get form() {
    return this.addressForm.controls;
  }

  makeAddressForm() {
    return this.fs.builder.group({
      locationType: ['pickup'],
      state: [null, [Validators.required]],
      avenue: [null],
      area: [null, [Validators.required]],
      addressType: ['residential_building', Validators.required],
      block: [null, [Validators.required]],
      street: [null, [Validators.required]],
      buildingNo: [null],
      apartmentNo: [null],
      additionalInfo: [null]
    });
  }

  getLocation(place: Places) {
    this.Location = place;

    const loc = place.fullAddress;
    this.addressForm.patchValue({
      state: loc[3] ? loc[3].long_name : '',
      area: loc[2] ? loc[2].long_name : '',
      street: loc[1] ? loc[1].long_name : ''
    });

  }

  // getSubLocation(key,locs){
  //   let subLoc = '';
  //   locs.forEach( loc => {
  //     if(loc.types.indexOf(key)!== -1){
  //       subLoc = loc.long_name
  //       return;
  //     }
  //   })
  //   return subLoc;
  // }

  closeSelector() {
    this.closeModal.emit();
  }

  setLocation() {
    if (this.addressForm.valid) {
      this.addressForm.value.lat = this.Location.location.lat;
      this.addressForm.value.long = this.Location.location.lng;
      this.addressForm.value.countryCode = this.Location.country;
      this.addressForm.value.vehicleId = this.vehicle;
      this.spinner.show();
      this.postEnquiryService.getLocationId(this.addressForm.value).subscribe(opts => {
        this.loc = opts;
        this.getAddress.emit({locationId: this.loc.id, location: this.Location});
        this.closeSelector();
        this.spinner.hide();
      }, (error) => {
        if (error.status === 400) {
          Swal.fire({
            title: this.languageKeys.oops,
            text: error.error.msg,
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.spinner.hide();
        this.errorHandler.handleError(error);
      });
    } else {
      this.submittedForm = true;
    }
  }
}
