import { Component, OnInit } from '@angular/core';
import { AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { GeoLocationService } from 'src/app/services/geo-location-service.service';
import { } from 'googlemaps';

@Component({
  selector: 'logibids-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements AfterViewInit {

  title = 'angular-gmap';

  map: google.maps.Map;

  lat = 29.3117;

  lng = 47.4818;

  zoom = 10;

  coordinates;

  mapOptions: google.maps.MapOptions;

  marker;

  constructor() {
  }
  ngAfterViewInit() {
    this.mapInitializer();
  }
  mapInitializer() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.coordinates = new google.maps.LatLng(this.lat, this.lng);
        this.mapOptions = {
          center: this.coordinates,
          zoom: this.zoom
        };
        this.marker = new google.maps.Marker({
          position: this.coordinates,
          map: this.map,
        });
      }, (error) => {
        console.log('Location error : ', error);
      });
    } else {
      console.log('Failed load location . . .');
    }
  }
}
