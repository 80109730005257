import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ServicesModule} from 'src/app/services/services.module';
import {APP_BASE_HREF} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {AccordionModule} from 'primeng/accordion';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CarouselModule} from 'primeng/carousel';
import {InputTextModule} from 'primeng/inputtext';
import {ChartModule} from 'primeng/chart';
import {CalendarModule} from 'primeng/calendar';
import {ButtonModule} from 'primeng/button';
import {MessagesModule} from 'primeng/messages';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {SelectButtonModule} from 'primeng/selectbutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SharedModule} from 'primeng/api';
import {ToolbarModule} from 'primeng/toolbar';
import {PanelModule} from 'primeng/panel';
import {FieldsetModule} from 'primeng/fieldset';
import {TooltipModule} from 'primeng/tooltip';
import {TreeTableModule} from 'primeng/treetable';
import {getBaseLocation} from './helpers/common-functions.util';
import {LogibidCommonModule} from './common/logibid-common.module';
import {SocialLoginModule, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';

import {NgxPermissionsModule} from 'ngx-permissions';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgxStarRatingModule } from 'ngx-star-rating';
// import { provideFirebaseApp } from '@angular/fire/app';
// import { initializeApp } from 'firebase/app';
// import { getAuth, provideAuth } from '@angular/fire/auth'; 
// import { ServiceWorkerRegister } from './service-worker/servicewoker';
import { environment } from 'src/environments/environment';



const sconfig: SocketIoConfig = { url: 'wss://api.logibids.com/', options: { transports: ['websocket'] } };

// export function startupServiceFactory(
//   InitialService: ServiceWorkerRegister
// // eslint-disable-next-line @typescript-eslint/ban-types
// ): Function {
//   return () => InitialService.publicApi();
// }

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('652205762202990')
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('41842724806-kc2j1qejbb3kta5o3sh80dvo327sr4v5.apps.googleusercontent.com')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    LogibidCommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServicesModule.forRoot(),
    AccordionModule,
    ToastModule,
    CarouselModule,
    InputTextModule,
    ChartModule,
    CalendarModule,
    ButtonModule,
    MessagesModule,
    ConfirmDialogModule,
    DialogModule,
    SelectButtonModule,
    AutoCompleteModule,
    SharedModule,
    AccordionModule,
    ToolbarModule,
    PanelModule,
    FieldsetModule,
    NgxStarRatingModule,
    TooltipModule,
    TreeTableModule,
    SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBOdShPph5r1xujbMvvK1NPpZn-T4QIoRQ',
      language: 'ar',
      libraries: ['places', 'geometry']
    }),
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth()),
    AgmDirectionModule,
    SocketIoModule.forRoot(sconfig),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: startupServiceFactory,
    //   deps: [ServiceWorkerRegister],
      
    //   multi: true
    // },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
