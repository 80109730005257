import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './guards/auth_guards';
import {PublicGuard} from './guards/public_guards';
import {NgxPermissionsGuard} from 'ngx-permissions';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'get-started',
  //   pathMatch: 'full'
  // },
  {
    path: 'get-started',
    loadChildren: 'src/app/home/home.module#HomeModule',
    canActivate: [PublicGuard]
  },
  {
    path: 'login',
    loadChildren: 'src/app/login/login.module#LoginModule',
    canActivate: [PublicGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: 'src/app/forgot-password/forgot-password.module#ForgotPasswordModule',
    canActivate: [PublicGuard]
  },
  {
    path: 'public',
    loadChildren: 'src/app/public-pages/public-pages.module#PublicPagesModule',
  },
  {
    path: 'signup',
    loadChildren: 'src/app/signup/signup.module#SignupModule',
    canActivate: [PublicGuard]
  },
  {
    path: 'vehicle',
    loadChildren: 'src/app/vehicle-selector/vehicle-selector.module#VehicleSelectorModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'post-enquiry',
    loadChildren: 'src/app/post-enquiry/post-enquiry.module#PostEnquiryModule',
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'CLIENT'
      }
    }
  },
  {
    path: 'location-selector',
    loadChildren: 'src/app/location-selector/location-selector.module#LocationSelectorModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'schedule-ride',
    loadChildren: 'src/app/schedule-ride/schedule-ride.module#ScheduleRideModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'auctions',
    loadChildren: 'src/app/auctions/auctions.module#AuctionsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'my-profile',
    loadChildren: 'src/app/user-profile/user-profile.module#UserProfileModule',
    canActivate: [AuthGuard]
  }, {
    path: 'my-trips',
    loadChildren: 'src/app/my-trips/my-trips.module#MyTripsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'my-enquiry',
    loadChildren: 'src/app/my-enquiry/my-enquiry.module#MyEnquiryModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: 'src/app/payment/payment.module#PaymentModule',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'my-enquiry'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
