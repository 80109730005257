import {Injectable} from '@angular/core';
import {EnquiryItem} from '../models/enquiries';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  constructor(private http: HttpClient) {
  }

  getProfile() { // -------------Get user Profile ---------------
    return this.http.get(`current-user`)
      .pipe(map(event => event));
  }

  updateUser(data) { // -------------Update User profile ---------------
    return this.http.post(`update`, data)
      .pipe(map(event => event));
  }
  updateUserLanguage(lang) { // -------------Update preferred language ---------------
    console.log(lang)
    return this.http.post(`update-preferred-language`, {language:lang})
      .pipe(map(data => data));
  }
  upload(formData) {
    return this.http.post('uploads/image', formData, {reportProgress: true, observe: 'events'})
      .pipe(map(event => event));
  }
}
