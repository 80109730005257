import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {NearByDriverResponse, Places, SelectOptions} from '../../models/common';
import {NgxSpinnerService} from 'ngx-spinner';
import * as constants from '../../constants';
import {ErrorHandlerService} from '../../services/error-handler.service';
import {PostEnquiryService} from '../../services/post-enquiry.service';
import {languageConstants} from '../../constants/languageConstants';
import { GoogleLocationSelectorComponent } from '../google-location-selector/google-location-selector.component';

@Component({
  selector: 'app-location-selector-delivery',
  templateUrl: './location-selector-delivery.component.html',
  styleUrls: ['./location-selector-delivery.component.css']
})
export class LocationSelectorDeliveryComponent implements OnInit, OnChanges {
  addressForm: FormGroup;
  Location: Places;
  submittedForm = false;
  drivers: [];
  addressType: SelectOptions[] = constants.OTHER_ADDRESS_TYPE[localStorage.getItem('language') || 'en'];
  @Input() show: boolean;
  @Input() category: number;
  @Input() title: string;
  @Output() closeModal = new EventEmitter();
  @Output() setDrivers = new EventEmitter();
  @Output() getAddress = new EventEmitter();
  loc: any;
  language = 'en';
  languageConstants = languageConstants;
  languageKeys = languageConstants[this.language];
  // @ViewChild('gmap',{static:false}) gmap : GoogleLocationSelectorComponent
  
  constructor(private fs: FormService,
              private spinner: NgxSpinnerService,
              private errorHandler: ErrorHandlerService,
              private postEnquiryService: PostEnquiryService,
  ) {
    const langu = localStorage.getItem('language');
    if(langu) {
      this.language = langu;
      this.languageKeys = this.languageConstants[langu]
    }
  }

  ngOnInit() {
    console.log(this.show);
    this.addressForm = this.makeAddressForm();
  }

  get form() {
    return this.addressForm.controls;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getDrivers(this.Location);
  }

  makeAddressForm() {
    return this.fs.builder.group({
      locationType: ['pickup'],
      state: [null, [Validators.required]],
      avenue: [null],
      area: [null, [Validators.required]],
      addressType: ['residential_building', [Validators.required]],
      block: [null, [Validators.required]],
      street: [null, [Validators.required]],
      buildingNo: [null],
      apartmentNo: [null],
      additionalInfo: [null]
    });
  }

  getLocation(place: Places) {
    this.Location = place;
    const loc = place.fullAddress;
    this.getDrivers(place);
    this.addressForm.patchValue({
      state: loc[3] ? loc[3].long_name : '',
      area: loc[2] ? loc[2].long_name : '',
      street: loc[1] ? loc[1].long_name : ''
    });

  }

  // resetLocation() {
  //   console.log('reset!')
  //   this.gmap.setCurrentLocation();
  // //  this.addressForm.reset(); 
  // }


  closeSelector() {
    this.closeModal.emit();
  }

  setLocation() {
    if (this.addressForm.valid) {
      this.addressForm.value.lat = this.Location.location.lat;
      this.addressForm.value.long = this.Location.location.lng;
      this.addressForm.value.countryCode = this.Location.country;
      this.addressForm.value.enquiryType = 'DELIVERY';
      this.spinner.show();
      this.postEnquiryService.getLocationId(this.addressForm.value).subscribe(opts => {
        this.loc = opts;
        this.getAddress.emit({locationId: this.loc.id, location: this.Location});
        this.closeSelector();
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.errorHandler.handleError(error);
      });
      this.closeSelector();
    } else {
      this.submittedForm = true;
    }
  }

  getDrivers(place) {
    this.postEnquiryService.getLocaleDrivers(place.location, this.category).subscribe((opts: any) => {
      this.setDrivers.emit(opts);
      this.drivers = opts.map(d => ({
        lat: d.lat,
        lng: d.long,
        label: `${d.name} (${parseInt(String(d.duration / 60), 10)} mins)`,
        draggable: false
      }));
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.errorHandler.handleError(error);
    });
  }
}
