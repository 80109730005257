export const languageConstants = {
  en: {
    HOWWOULDYOURATETHEOVERALLQUALITYOFTRIP:'How would you rate the overall quality of this trip',
    AUCTIONENDPOINT:'Auction end time',
    NOTRIPSFOUND:'NO Trips Found',
    VIEW:'View',
    STARTTIME:'Start time',
    SELECTVEHICLE:'Select Vehicle',
    SELECTBRAND:'Select Brand',
    PLEASESELECTSUBCATEGORY:'Please select subcategory',
    PLEASESELECTVEHICLE:'Please Select Vehicle',
    ENTERAMOUNT:'Enter Amount',
    POSTENQUIRYY:'Post Enquiry',
    POSTENQUIRY:'POST ENQUIRY',
    MYENQUIRIES:'  My Enquiries',
    TRANSPORTGOODS:'TRANSPORT GOODS',
    ENQUIRY:'ENQUIRY',
    PLEASEADDCOMMENT:'Please Add Your Comment',
    TRANSPORTPUBLIC:'TRANSPORT PUBLIC',
    COMMODITY:'Commodity',
    TRIPS:'Trips',
    ASSISTANCE:'Assistance',
    INSURANCE:'Insurance',
    DRIVERRATING:'Driver Rating',
    LOWESTBID:'Lowest Bid',
    LOCATION:'Location',
    EDIT:'Edit',
    EDITPROFILE:'Edit Profile',
    MYPROFILE:'My Profile',
    CONFIRMBID:'Confirm bid',
    ENQUIRYCHECKOUTPAGE:'Enquiry Checkout Page',
    DELETE:'Delete',
    CONFIRM:'Confirm',
    ADDITIONALINFORMATION:'Additional Information',
    PAYDIRECTLYTOSERVICEPROVIDER:'Pay Directly To Service Provider',
    TOTALAMOUNT:'Total Amount',
    SERVICECHARGE:'Service Charge',
    CHOOSEPAYMENTMETHOD:'Choose your Payment Method',
    MINUTES:'Mins',
    HR:'hr',
    WIDTH:'Width',
    PLEASESELECTCOMMODITY:'Please Select Commodity',
    SELECTCOMMODITY:'Select Commodity',
    LENGTH:'Length',
    HEIGHT:'Height',
    ENTERCODE:'Enter Code',
    NONOTIFICATIONAVAILABLE:'No Notification Available',
    TERMSANDCONDITIONS:'Terms And Conditions',
    DELETEACCOUNT:'Delete Account',
    LOGOUT:'Logout',
    MYWALLET:'My Wallet',
    MyTRIP:'My Trip',
    ENTERYOURPASSWORD:'Enter Your Password',
    CONFIRMPASSWORD:'Confirm Password',
    LOGIN:'Login',
    CONFIRMPICKUP:'Confirm Pickup',
    SETYOURLOCATION:'Set your Location',
    LANGUAGE:'Language',
    GETSTARTED:'Get Started',
    ENTERPHONENUMBER:'Enter your phone number',
    ENTERNEWPASSWORD:'Enter New Password',
    ENTERCODENEWPASSWORD:'Enter Your Code And New Password',
    FORGOTPASSWORD:'Forgot Password',
    COPYRIGHT:'Copyright © 2010-2020 (Transport Service)',
    NEXT:'Next',
    WALLETBALANCE:'Wallet Balance',
    ALLBRAND:'All Brands',
    BIDNOW:'Bid Now',
    TRANSPORT:'Transport',
    VEHICLEREQUIRED:'Vehicle is required',
    RENTAL:'Rental',
    RENTALOTHERS:'RENTAL OTHERS',
    WITHDRAW:'Withdraw',
    YOURWALLETBALANCE:'Your Wallet Balance is',
    DELIVERY:'Delivery',
    ADD:'Add',
    UPLOADYOURID:'Upload Your ID',
    NOOFTRIPS:'No of Trips',
    DOCUMENTS:'Documents',
    UPLOADCOMPANYLICENSE:'Upload Company License',
    ACCEPTTERMSANDCONDITION:'Accept Terms & Conditions',
    SUBMIT:'Submit',
    FUEL:'Fuel',
    REJECTTRIP:'Reject Trip',
    PICKUP:'Pickup',
    DELIVER:'Deliver',
    CALL:'Call',
    ACCEPTTRIP:'Accept Trip',
    SHOWMAP:'Show Map',
    DRIVER:'Driver',
    SERVICE:'Service',
    DAYS:'Days',
    HOURSSLASHDAY:' Hours/Day',
    HOURS:'Hours',
    HOURSPERDAY:'Hours per Day',
    SCHEDULETIME:'Schedule Time',
    BIDSTARTTIME:'Bid Start Time',
    BIDENDTIME:'Bid End Time',
    PICKUPLOCATION:'Pickup Location',
    NOOFDAYS:'No of Days',
    KMSPERDAY:'Kms per Day',
    CREATEBID:'Create Bid',
    ENQUIRYENDS:'ENQUIRY ENDS',
    PRIVACYPOLICY:'Privacy Policy',
    NAME:'Name',
    TO:'To',
    CONTACT:'Contact',
    EMAIL:'Email',
    CUSTOMERRATING:'Customer Rating',
    BIDFOR:'BID FOR',
    FROM:'FROM',
    YOU:'YOU',
    GOTOTRIP:'Go to Trip',
    USERDETAILS:'User Details',
    DRIVERDETAILS:'Driver Details',
    WEIGHTOFCOMMODITY:'Weight of Commodity',
    DELIVERYCHARGE:'Delivery Charge',
    TOTALDISTANCE:'Total Distance',
    PICKUPSCHEDULED:'Pick up Schedule',
    TRACKDRIVER:'Track Driver',
    TYPEOFCOMMODITY:'Type of Commodity',
    REMAININGTIMEFORPICKUP:'REMAINING TIME FOR PICKUP',
    ADDBIDAMOUNT:"Add Bid Amount",
    ENQUIRYDETAILPAGE:'Enquiry Detail Page',
    TYPEOFVEHICLE:'Type of Vehicle',
    HOME:'HOME',
    ENQUIRYID:'Enquiry ID',
    ADDDRIVER:'Add Driver',
    ADDVEHICLE:'Add Vehicle',
    COMPLETEYOURPROFILE:'Complete Your Profile',
    NOACTIVEBID:'There Is No Active Bid For You',
    YOURLASTBID:'Your last bid',
    SEARCHENQUIRY:'Search Enquiry',
    NORECORDSFOUND:'No Records Found',
    AUCTIONS:'Auctions',
    RUNNING: 'RUNNING',
    PENDING: 'PENDING',
    ENDED: 'ENDED',
    CANCEL:'Cancel',
    CANCELED: 'CANCELED',
    CANCELLED: 'CANCELLED',
    BIDDING_STARTED: 'BIDDING STARTED',
    HALF_TIME: 'HALF TIME OVER',
    ENDING_SOON: 'ENDING SOON',
    BIDS: 'BIDS',
    login_error_1: 'Please provide valid details !',
    Required: '*Required',
    Invalid: '*Invalid',
    Invalid_PhnNumber: '* Valid Phone Number Required',
    password_character:' * The password must be at least 8 characters, 1 lowercase ,1 uppercase ,1 number, 1 special characters',
    driver_message: 'Are you sure that you want to delete this driver?',
    acpt_lbl:'yes',
    rjct_lbl:'no',
    passwword_must:'  * Passwords must match',
    forgot_pwd_msg: 'Please Enter Valid Phone Number !',
    transport_good_detail: 'Are you sure that you want to delete this enquiry?',
    account_message: 'Are you sure that you want to delete your account ?',
    bid_closed:'Bid Closed',
    enq_closed:'Enquiry has been closed !',
    enq_reject:'Enquiry has been rejected !',
    signup_success: 'Signup Successfull',
    confirm:  'Please wait 5 seconds for verification!',
    verification: 'Verification Successfull',
    otp: 'OTP sent successfully',
    wrong: 'Something went wrong',
    upload: 'Upload Document',
    profile_updated: 'Profile Updated !',
    pr_pwd_updated:'Profile & Password Updated !',
    enq_success: 'Enquiry Posted successfully',
    enq_failed: 'Failed to submit enquiry',
    pay_success: 'Payment Success',
    enq_confirm: 'Enquiry has been Confirmed !',
    updated_pwd: 'Succesfully Updated Password',
    oops: 'Oops',
    Driver:'Driver',
    DELIVERYLOCATION:'Delivery Location',
    OK: 'OK',
    ADDMORE:'Add More',
    TRIPDISTANCE:'Trip Distance',
    ACCEPTED:'ACCEPTED',
    PAY:'Pay',
    NETBANKING:'Net Banking',
    CREDITDEBITCARD:'Credit / Debit Cards',
    WALLET:'Wallet',
    HESABE:'Hesabe',
    PAYMENTFAILED:'Payment Failed',
    PROCESSINGCOMPLETE:'Processing Completed',
    GOHOME:'Go Home',
    PAYMENT:'Payment',
    REGESTRATIONFAILED:'Registration Failed',
    SCHEDULEDSTART:'Schedule Start',
    SELECTCATEGORY:'Select Category',
    LIGHTTRUCK:'Light Truck',
    MINITRUCK:'Mini Truck',
    EXAVATOR:'Excavator',
    PAYMENTSUCCESFULL:'Payment Successfull',
    SELECTPICKUPLOCATION:'Select Pickup Location',
    SELECTDELIVERYLOCATION:'Select Delivery Location',
    SELECTHOURSPERDAY:'Select hours per day',
    SELECTRIPS:'Select Trips',
    CAR:'Car',
    BUS:'Bus',
    TRAILOR:'Trailor',
    CONTACTUS:'Contact US',
    TIME:'Time',
    USERTYPE:'User Type',
    SCHEDULERIDE:'Schedule a Ride',
    SIGNUP:'Signup',
    YOURACCOUNT:'Your Account',
    DONTRECIEVE:"Don't receive",
    AGREECONTINUE:'Agree & Continue',
    LOGIBIDSDEPOSITAMOUT:'Deposit Amount',
    passwordstr1:'1) Your password must contain at least 8 characters.',
    passwordstr2:'2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)',
    passwordstr3:'3) Your password must contain at least one lowercase letter.',
    passwordstr4:'4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)',
    passwordstr5:'5) Your password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,)',
    NOVEHICLEFOUND:'No Vehicles Found',
    RENTALS:'Rentals',
    YOURNAME:'Your Name',
    YOUREMAIL:'Your Email (optional)',
    CHOOSEYOURSERVICES:'Choose Your Services',
    IMMEDIATEDELIVERY:'Immediate Delivery',
    SCHEDULEDDELIVERY:'Scheduled Delivery',
    APPARTMENTNUMBER:'Apartment Number',
    BUILDINGNUMBER:'Building Number',
    AVENUE:'Avenue',
    STREET:'Street',
    BLOCK:'Block',
    AREA:'Area',
    STATE:'State',
    PLEASESELECTADDRESSTYPE:'Please Select addressType',
    SEARCHNEARESTLOCATION:'Search Nearest Location',
    OLDPASSWORD:'Old Password',
    NEWPASSWORD:'New Password',
    YOUREMAILL:'Your Email',
    LOGIBIDSONFACEBOOK:'Logibids on Facebook',
    LOGIBIDSONINSTAGRAM:'Logibids on Instagram',
    NORESULTSFOUND:'No Results Found',
    ADDMONEY:'Add Money',
    GOODS:'Goods',
    PUBLICTRANSPORT:'Public Transport',
    GOODSTRANSPORTRENTALS:'Goods Transport Rental',
    HEAVYEQUIPEMENTS:'Heavy Equipments',
    OTHERS:'Others',
    PAIDFOR:'PAID FOR',
    ADDEDMONEYTOWALLET:'ADDED MONEY TO WALLET',
    CONTACTUSS:'Contact',

  },
  ar: {
    CONTACTUSS:'اتصل بنا',
    ADDEDMONEYTOWALLET:'تمت إضافة الأموال إلى المحفظة',
    PAIDFOR:'مدفوع لأجل',
    OTHERS:'اخرى',
    HEAVYEQUIPEMENTS:'معدات ثقيلة',
    GOODSTRANSPORTRENTALS:'تأجير نقل البضائع',
    PUBLICTRANSPORT:'النقل العام',
    GOODS:'البضائع',
    ADDMONEY:'إضافة المال',
    NORESULTSFOUND:'لم يتم العثور على نتائج',
    LOGIBIDSONINSTAGRAM:'لوجيبيدس على إنستغرام',
    LOGIBIDSONFACEBOOK:'لوجيبيدس على الفيسبوك',
    YOUREMAILL:'بريدك الإلكتروني (اختياري)',
    NEWPASSWORD:'كلمة المرور الجديدة',
    OLDPASSWORD:'كلمة المرور القديمة',
    PLEASEADDCOMMENT:'الرجاء إضافة تعليقك',
    SEARCHNEARESTLOCATION:'البحث عن موقع قريب',
    PLEASESELECTADDRESSTYPE:'يرجى تحديد العنوان',
    STATE:'دولة',
    AREA:'دولة',
    BLOCK:'قطعة',
    STREET: 'شارع',
    AVENUE:'جادة',
    BUILDINGNUMBER:'رقم المبنى (اختياري)',
    APPARTMENTNUMBER:'رقم الشقة (اختياري)',
    ADDITIONALINFORMATION:'معلومات إضافية (اختياري)',
    ENQUIRYID:'معرف الاستفسار',
    HOURSSLASHDAY:'ساعات / يوم',
    SERVICE:'الخدمات',
    FUEL:'الخدمات',
    DRIVER:'سائق',
    CONFIRMPASSWORD:'تأكيد كلمة المرور',
    YOURNAME:'الاسم',
    YOUREMAIL:'بريدك الإلكتروني (اختياري)',
    SCHEDULEDDELIVERY:'تم تحديد موعد التسليم',
    IMMEDIATEDELIVERY:'توصيل سريع',
    PLEASESELECTSUBCATEGORY:'الرجاء تحديد الفئة الفرعية',
    STARTTIME:'وقت البدء',
    AGREECONTINUE:'موافقة ومتابعة',
    DONTRECIEVE:'لا تتلقى ',
    YOURACCOUNT:'الحساب الخاص بك',
    SIGNUP:'موافقة ومتابعة',
    CHOOSEYOURSERVICES:'اختر خدمتك',
    RENTALS:'الإيجارات',
    NOVEHICLEFOUND:'لم يتم العثور على مركبات',
    EDIT:'يحرر',
    USERTYPE:'نوع المستخدم',
    MYPROFILE:'الى الخلف',
    EDITPROFILE:'الى الخلف',
    SCHEDULERIDE:'جدولة رحلة',
    TIME:'الى الخلف',
    CONTACTUS:'اتصل بنا',
    CAR:'سيارة',
    BUS:'حافلة',
    TRAILOR:'تريلر',
    DELIVERYLOCATION:'الى الخلف',
    SELECTTRIPS:'حدد الرحلات',
    SELECTHOURSPERDAY:'حدد ساعات في اليوم',
    LIGHTTRUCK:"شاحنة خفيفة",
    MINITRUCK:'شاحنة صغيرة',
    EXAVATOR:'حفارة',
    PICKUPLOCATION:'اختر موقعا',
    BIDENDTIME:'وقت انتهاء العرض',
    BIDSTARTTIME:'وقت بدء المزايدة',
    SCHEDULETIME:'جدولة الوقت',
    SELECTBRAND:'اختيار العلامة التجارية',
    PLEASESELECTVEHICLE:'الرجاء تحديد مركبة',
    SELECTVEHICLE:'اختر السيارة',
    SELECTDELIVERYLOCATION:'حدد موقع التسليم',
    SELECTPICKUPLOCATION:'البحث عن استفسار',
    AUCTIONENDPOINT:'وقت انتهاء المزاد',
    PLEASESELECTCOMMODITY:'الرجاء تحديد السلع',
    SELECTCOMMODITY:'حدد السلعة',
    SELECTCATEGORY:'حدد الفئة',
    POSTENQUIRYY:'قدم استفسارك هنا',
    SCHEDULEDSTART:'بدء الجدول الزمني',
    HR:'ساعة',
    PAYMENT:'تسديد',
    PAYMENTSUCCESSFULL:'تم الدفع بنجاح',
    PROCESSINGCOMPLETE:'اكتملت المعالجة',
    GOHOME:'اذهب للمنزل',
    PAYMENTFAILED:'فشل في عملية الدفع',
    REGESTRATIONFAILED:'فشل في التسجيل',
    LOGIBIDSDEPOSITAMOUT:'مبلغ إيداع ',
    HESABE:'حسابي',
    CREDITDEBITCARD:'بطاقات الائتمان / الخصم',
    NETBANKING:'صافي المصرفية',
    PAY:'ادفع الأن',
    WALLETBALANCE:'رصيد المحفظة',
    WALLET:'محفظة',
    CHOOSEPAYMENTMETHOD:'اختر طريقتك في الدفع',
    ADDMORE:'أضف المزيد',
    WITHDRAW:'انسحب',
    YOURWALLETBALANCE:'رصيد محفظتك هو',
    ENTERAMOUNT:'أدخل المبلغ',
    TRIPDISTANCE:'مسافة الرحلة',
    CANCEL:'إلغاء',
    HOWWOULDYOURATETHEOVERALLQUALITYOFTRIP:'كيف تقيم الجودة الشاملة لهذه الرحلة؟',
    NOTRIPSFOUND:'لم يتم العثور على رحلات',
    VIEW:'عرض',
    POSTENQUIRY:'قدم استفسارك هنا', 
    MYENQUIRIES:'استفساراتي',
    TRANSPORTPUBLIC:'النقل العام',
    TRANSPORTGOODS:'نقل البضائع',
    ENQUIRY:'إلغاء الطلب',
    COMMODITY:'سلعة',
    TRIPS:'رحلات',
    RENTALOTHERS:'تأجير الآخرين',
    ASSISTANCE:'مساعدة',
    INSURANCE:'تأمين',
    DRIVERRATING:'تأمين',
    LOWESTBID:'أدنى مزايدة',
    LOCATION:'موقعك',
    CONFIRMBID:'موقعك',
    ENQUIRYCHECKOUTPAGE:'صفحة الاستعلام عن الخروج',
    DELETE:'حذف',
    CONFIRM:'ـتأكيد',
    PAYDIRECTLYTOSERVICEPROVIDER:'ـتأكيد',  
    TOTALAMOUNT:'ـتأكيد',
    SERVICECHARGE:'تكلفة الخدمة',
    WIDTH:'عرض',
    LENGTH:'الطول',
    HEIGHT:'ارتفاع',
    ENTERCODE:'ادخل الرمز',
    NONOTIFICATIONAVAILABLE:'لا توجد إخطارات متاحة',
    DELETEACCOUNT:'لا توجد إخطارات متاحة',
    LOGOUT:'تسجيل خروج',
    MYWALLET:'محفظتي',
    MyTRIP:'رحلتي',
    ENTERYOURPASSWORD:'أدخل كلمة مرور صالحة',
    LOGIN:'تسجيل الدخول',
    CONFIRMPICKUP:'تأكيد الاستلام',
    SETYOURLOCATION:'حدد موقعك',
    LANGUAGE:'لغة',
    GETSTARTED:'البدء',
    ENTERPHONENUMBER:'أدخل رقم هاتفك',
    ENTERNEWPASSWORD:'أدخل كلمة المرور الجديدة',
    ENTERCODENEWPASSWORD:'أدخل الرمز الخاص بك وكلمة المرور الجديدة',
    FORGOTPASSWORD:'هل نسيت كلمة المرور؟',
    COPYRIGHT:'حقوق النشر © 2010-2020 (خدمة النقل)',
    NEXT:'التالي',
    DAYS:'أيام',
    HOURS:'ساعات',
    VEHICLEREQUIRED:'مطلوب مركبة',
    ADDBIDAMOUNT:'أضف مبلغ العطاء',
    MINUTES:'دقائق',
    SUBMIT:'يُقدِّم',
    ALLBRAND:'جميع العلامات التجارية',
    TRANSPORT:'ينقل',
    RENTAL:'تأجير',
    ACCEPTTRIP:'قبول الرحلة',
    UPLOADCOMPANYLICENSE:'تحميل ترخيص الشركة',
    ACCEPTTERMSANDCONDITION:'قبول الشروط والأحكام',
    PICKUP:'يلتقط',
    DOCUMENTS:'وثائق',
    UPLOADYOURID:'قم بتحميل معرفك',
    DELIVER:'يسلم',
    REJECTTRIP:'رفض الرحلة',
    ADD:'يضيف',
    TRACKDRIVER:'سائق المسار',
    BIDNOW:'المزايدة الآن',
    SHOWMAP:'عرض الخريطة',
    FROM:'من',
    TO:'ل',
    CALL:'إتصال',
    NAME:'اسم',
    CONTACT:'رقم الاتصال',
    CONTRACT:'عقد',
    EMAIL:'بريد إلكتروني',
    CUSTOMERRATING:'تقييم العملاء',
    PRIVACYPOLICY:'سياسة الخصوصية',
    TERMSANDCONDITIONS:'الأحكام والشروط',
    BIDFOR:'يراهن على',
    YOU:'أنت',
    HOURSPERDAY:'ساعة في اليوم',
    NOOFTRIPS:'عدد الرحلات',
    TYPEOFVEHICLE:'نوع السيارة',
    GOTOTRIP:'اذهب إلى الرحلة',
    USERDETAILS:'بيانات المستخدم',
    CREATEBID:'إنشاء عرض',
    DRIVERDETAILS:'تفاصيل السائق',
    WEIGHTOFCOMMODITY:'وزن السلعة',
    DELIVERYCHARGE:'رسوم التوصيل',
    TOTALDISTANCE:'المسافة الكلية',
    PICKUPSCHEDULED:'وقت الاستلام',
    TYPEOFCOMMODITY:'نوع السلعة',
    REMAININGTIMEFORPICKUP:'الوقت المتبقي للاستلام',
    ENQUIRYDETAILPAGE:'تفاصيل صفحة الطلب',
    HOME:'الصفحة الرئيسية',
    ADDDRIVER:'إضافة سائق',
    ADDVEHICLE:'أضف مركبة',
    COMPLETEYOURPROFILE:'أكمل ملفك الشخصي',
    NOACTIVEBID:'لا يوجد عرض نشط بالنسبة لك',
    YOURLASTBID:'عرضك الأخير',
    SEARCHENQUIRY:'البحث عن استفسار',
    ENQUIRYENDS:'انتهى التحقيق',
    NORECORDSFOUND:'لا توجد سجلات',
    AUCTIONS:'المزادات',
    MYENQUIRY:'طلباتي',
    DELIVERY: 'توصيل',
    RUNNING: 'جاري التنفيذ',
    PENDING: 'قيد الانتظار',
    ENDED: 'انتهى',
    CANCELED: 'ألغيت',
    CANCELLED: 'ألغيت',
    BIDDING_STARTED: 'بدأ المزايدة',
    HALF_TIME: 'أنتهت نصف المدة',
    ENDING_SOON: 'ستنتهى قريبا',
    BIDS: 'لا عروض',
    login_error_1: 'الرجاء تقديم تفاصيل صحيحة!',
    Required: '*مطلوب',
    Invalid: '*غير صالحة',
    Invalid_PhnNumber: '* مطلوب رقم هاتف صالح',
    password_character:'* يجب أن تتكون كلمة المرور من 8 أحرف على الأقل ، حرف واحد صغير ، حرف كبير واحد ، رقم واحد ، حرف خاص واحد',
    driver_message: 'هل أنت متأكد أنك تريد حذف السائق هذا؟',
    acpt_lbl:'نعم',
    rjct_lbl:'لا',
    passwword_must:'* الكلمة السريه يجب ان تتطابق',
    forgot_pwd_msg: 'الرجاء إدخال رقم هاتف صحيح !',
    transport_good_detail: 'هل أنت متأكد أنك تريد حذف هذا الاستفسار؟',
    account_message: 'هل أنت متأكد أنك تريد حذف حسابك؟',
    bid_closed:'أغلقت المزايدة',
    enq_closed:'تم إغلاق التحقق!',
    enq_reject:'تم رفض الاستفسار!',
    signup_success: 'تسجيل ناجح',
    confirm:'الرجاء الانتظار خمس ثوان للتحقق!',
    verification: 'عملية التحقق ناجحة',
    otp: 'تم إرسال OTP بنجاح',
    wrong: 'هناك خطأ ما',
    upload: 'رفع المستند',
    profile_updated: 'تحديث الملف الشخصي !',
    pr_pwd_updated:'تم تحديث الملف الشخصي وكلمة المرور',
    enq_success: 'تم اضافة الطلب بنجاح',
    enq_failed: 'فشل إرسال الاستفسار',
    pay_success: 'عملية دفع ناجحة',
    enq_confirm: 'تم تأكيد الاستفسار!',
    updated_pwd: 'تم تحديث كلمة المرور بنجاح',
    oops: 'عفوا',
    Driver:'سائق',
    OK: 'حسنا',
    ACCEPTED:'وافقت',
    passwordstr1:'1) يجب أن تحتوي كلمة المرور الخاصة بك على 8 أحرف على الأقل.',
    passwordstr2:'2) يجب أن تحتوي كلمة مرورك على حرف واحد كبير أو كبير (على سبيل المثال: أ ، ب ، إلخ.)',
    passwordstr3:'3) يجب أن تحتوي كلمة مرورك على حرف صغير واحد على الأقل.',
    passwordstr4:'4) يجب أن تحتوي كلمة مرورك على رقم واحد على الأقل (على سبيل المثال: 0 ، 1 ، 2 ، 3 ، إلخ.)',
    passwordstr5:'5) يجب أن تحتوي كلمة مرورك على حرف خاص واحد على الأقل - على سبيل المثال: $، #، @،!،٪، ^، &، *، (،)'
  }
};
