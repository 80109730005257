// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // firebase: {
  //   apiKey: "AIzaSyAyz10_NdujQg3DSHWMflpgHUdqnBL6YYE",
  // authDomain: "logibids-fb2a8.firebaseapp.com",
  // projectId: "logibids-fb2a8",
  // storageBucket: "logibids-fb2a8.appspot.com",
  // messagingSenderId: "1003749513194",
  // appId: "1:1003749513194:web:ad4a3a447873e2a3ed8493",
  // vapidKey:
  // 'BK6Mtjs5AYvkgoyEyQgy0n9uLK8ZHustIVhpNpwGZ6_uRWtxokVaRbvHbpZ9lON0EtUcUvnOvtEuxQuAqT9BOYE',
  // measurementId: "G-BXD3RP6P0V"
  // },
  production: false,
  // apiBaseUrl: 'http://ec2-54-234-188-236.compute-1.amazonaws.com:3000/v1/',
  apiBaseUrl: 'https://logibidsapi.appgrowthcompany.com/v1/',
  defaultLang: 'en',
  GOOGLE_API_KEY: 'AIzaSyBOdShPph5r1xujbMvvK1NPpZn-T4QIoRQ',
  baseUrl: 'https://logibidsapi.appgrowthcompany.com/v1/',
  path: '/firebase-messaging-sw.js',
  scope: '/',
  pusher: {
      key: '8a95c1ef06e601684784',
      cluster: "eu",
      authEndpoint: 'https://logibidsapi.appgrowthcompany.com/v1/pusher-authenticate'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
