import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'logibid-form',
  template: '',
})
export class FormComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  disableSubmit = false;

  constructor(fs: FormService, private messageService: MessageService) {
  }

  ngOnInit() {
  }

  submit(data) { throw new Error('Not implemented!'); }

  onSubmit(secondaryForm?) {
    const form = secondaryForm || this.form;
    this.submitted = true;
    if (form.valid && !this.disableSubmit) {
      this.disableSubmit = true;
      this.submit(form.value);
      this.disableSubmit = false;
    } else if (!form.valid) {
      this.disableSubmit = false;
      if (this.messageService) {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Please provide appropriate / required information.'
        });
        setTimeout(() => {
          this.messageService.clear();
        }, 2000);
      }
    }
  }

  submittable() {
    this.disableSubmit = false;
    this.submitted = false;
  }
}
