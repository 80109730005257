import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {AuthServicee} from './services/auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {environment} from '../environments/environment';
import {MessageService} from 'primeng/api';
import {OneSignalService}  from './services/onesignal.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'logibids-client';
  validate;
  ltr: boolean;
  rtl: boolean;

  constructor(private router: Router,
              private auth: AuthServicee,
              private onesignal: OneSignalService,
              private permissionsService: NgxPermissionsService, private ms: MessageService,
  ) {
    
  }

  ngOnInit() {
    // this.validateToken();
    const perm = ['CLIENT', 'SERVICE_PROVIDER'];
    this.permissionsService.loadPermissions(perm);
    this.setLanguage();
    this.removeScript();
    this.setPermission();
    this.onesignalCheck()
    // this.permissionsService.loadPermissions(permissions);
    // this.http.get('url').subscribe((permissions) => {
    //    // const perm = ["ADMIN", "EDITOR"]; example of permissions
    //    this.permissionsService.loadPermissions(permissions);
    // });
  }

  ngOnDestroy() {
    clearInterval(this.validate);
  }

  setPermission() {
    const user = localStorage.getItem('user');
    if (user) {
      const {type} = JSON.parse(user);
      if (type !== 'CLIENT') {
        localStorage.clear();
        this.router.navigate(['/get-started']);
        this.ms.add({
          severity: 'error',
          summary: 'Session Expired'
        });
      }
    }
  }

  setLanguage() {
    const path = window.location.pathname.split('/');
    const supportedLanguage = ['ar', 'ur', 'en'];
    const rtlLang = ['ar', 'ur']; // Check included langauge
    if (supportedLanguage.includes((path[1]))) {
      localStorage.setItem('language', path[1]);
      if (rtlLang.includes(path[1])) { // Check direction
        this.rtl = true;
        this.ltr = false;
      } else {
        this.rtl = false;
        this.ltr = true;
      }
    }
  }

  removeScript() { // This script is to reload map with selected language
    const script = document.createElement('script');
    const language = localStorage.getItem('language') || 'en';
    script.src =
      `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.GOOGLE_API_KEY}
      &libraries=places&language=${language}`;
    document.head.appendChild(script);
  }
  onesignalCheck(){
    let onesignalToken=localStorage.getItem('onesignalToken');
    let user = JSON.parse(localStorage.getItem('user'));
    let notify=localStorage.getItem('notify');
    console.log('user : ',user)
    if(user){
      console.log('user token: ',user.web_player_id);
      console.log('Onesignal Token: ',onesignalToken);
      console.log('Subscribed : ',user.playerids.includes(onesignalToken))
      if(onesignalToken){
        console.log('Notify :',notify)
        if (notify=='granted'){
          if(user.playerids && (user.playerids.length<1||user.playerids.includes(onesignalToken)==false) ){
              this.onesignal.subscribeNotification(onesignalToken).subscribe((data)=>{
                console.log('Subscribed : ',data)
                localStorage.setItem('user',JSON.stringify(data))
              }, (error)=>{
                console.log('Subscription Failure :',error)
              })
            }
          }else if(notify==='denied') {
            this.onesignal.unSubscribeNotification(onesignalToken).subscribe((data)=>{
              console.log('unsubscribed : ',data)
              localStorage.setItem('user',JSON.stringify(data))
            }, (error)=>{
            console.log('Subscribe Failure :',error)
            })
          } else{
            localStorage.setItem('nativePrompt','show')
            // this.notifyPrompt()
          }
      }
    }
  }
  notifyPrompt(){
    Notification.requestPermission().then(permission => {
      console.log(permission);
      localStorage.setItem('notify', permission);
      this.onesignalCheck()
    });
  }
  // validateToken = () => {
  //   const token = localStorage.getItem('accessToken');
  //   const that = this;
  //   if (token) {
  //     that.auth.currentUserInfo().subscribe((status) => {
  //       if (!status) {
  //         that.router.navigate(['/get-started']);
  //       } else {
  //         console.log(status);
  //       }
  //     });
  //     that.validate = setInterval(() => {
  //       that.validateToken();
  //     }, 100000);
  //   }
  // }
}
